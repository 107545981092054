import React, { useState } from 'react';
import { Box, Container, Typography, Button, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CardMedia, Step, Stepper, StepLabel, makeStyles } from '@mui/material';
import LayoutType from '../../constants/layout-type';
import { KeyboardBackspace } from '@mui/icons-material';
import Colors from '../../constants/colors';
import { HeadFC } from 'gatsby';



const ActivationRequiredPage: React.FC = () => {
    const theme = useTheme();
    const isTV = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100vh',
                    pr: isTV ? 8 : 2,
                    pl: isTV ? 8 : 2
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant="h2" component="h1" align="center">
                        Screen Keep Activation Required
                    </Typography>
                    <Typography variant="h6" gutterBottom align="center" sx={{ mt: 2 }}>
                        It looks like this device's trial has expired. Please activate it to continue
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ mt: 4, mb: 1, maxWidth: 'md' }}>
                        Register at www.screenkeep.com on your computer or mobile device, then enter the activation code from the settings section.
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ mt: 7, mb: 8, maxWidth: 'md' }}>
                        Double press the back button on your remote control to access the settings section.
                    </Typography>
                </Container>
            </Box>
        </>
    );
};

ActivationRequiredPage.layoutType = LayoutType.TvDefault;

export default ActivationRequiredPage;

export const Head: HeadFC = () => <title>ScreenKeep - Activation Required</title>